<template>
  <header>
    <div
      id="header"
      class="header"
    >
      <h1 v-if="h1LogoShow">
        <nuxt-link
          class="header__logo"
          :to="{ name: 'home', params: { toTop: true }}"
          @click.native="clickToHome"
        >
          <img
            src="@/assets/image/header-logo.png"
            alt="智樂家"
            class="logo"
          >
        </nuxt-link>
      </h1>
      <nuxt-link
        v-else
        class="header__logo"
        :to="{ name: 'home', params: { toTop: true }}"
        @click.native="clickToHome"
      >
        <img
          src="@/assets/image/header-logo.png"
          alt="智樂家"
          class="logo"
        >
      </nuxt-link>
      <nav
        v-show="!isAgentOrder && !isGuestAgent"
        class="menu"
        :class="{'is-open': menuMobileIsOpen}"
      >
        <div
          id="menu-bar"
          class="menu-button"
          :class="{'is-open': menuMobileIsOpen}"
          @click="toggleMenuMobile"
        >
          <div
            id="bar1"
            class="bar"
          />
          <div
            id="bar2"
            class="bar"
          />
          <div
            id="bar3"
            class="bar"
          />
        </div>
        <client-only>
          <NavHeaderMenu
            :service-is-loading="serviceIsLoading"
            :all-service="allService"
            @logout="logoutDialogOpen = true"
          />
          <transition name="drawer">
            <NavSidebarDrawer
              :service-is-loading="serviceIsLoading"
              :open="menuMobileIsOpen"
              :class="{ 'active': menuMobileIsOpen }"
              :all-service="allService"
              @closeDrawer="menuMobileIsOpen = false"
              @logout="logoutDialogOpen = true"
            />
          </transition>
        </client-only>
      </nav>
      <img
        v-show="showAlertIcon"
        class="warning-alert"
        src="@/assets/image/edit-alert.svg"
      >
      <div
        v-show="hasRedPoint && isMobile && !showAlertIcon"
        class="red-dot"
      />
    </div>
    <v-dialog
      v-model="logoutDialogOpen"
      max-width="530"
    >
      <v-card>
        <v-card-title>
          您確定要登出智樂家?
        </v-card-title>
        <v-card-text />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="logoutDialogLoading"
            @click="logoutDialogOpen = false"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            :loading="logoutDialogLoading"
            @click="clickLogout"
          >
            登出
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import NavHeaderMenu from '@/components/Nav/NavHeaderMenu'
import NavSidebarDrawer from '@/components/Nav/NavSidebarDrawer'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavHeaderMenu,
    NavSidebarDrawer
  },
  data () {
    return {
      serviceIsLoading: true,
      allService: [],
      menuMobileIsOpen: false,
      logoutDialogOpen: false,
      logoutDialogLoading: false,
      showDropdownMenu: false
    }
  },
  computed: {
    ...mapGetters(['isAgentOrder', 'isGuestMember', 'isGuestAgent', 'hasRedPoint', 'isMobile']),
    showAlertIcon () {
      return (this.isLogin && !this.$auth?.user?.IsServiceAccountTransferEnd) && this.isMobile
    },
    isLogin () {
      return this.$auth.loggedIn
    },
    h1LogoShow () {
      const showPage = ['home', 'informationStation', 'index-id', 'login']
      const path = this.$route.name
      return showPage.includes(path)
    }
  },
  watch: {
    $route (val) {
      this.menuMobileIsOpen = false
    },
    menuMobileIsOpen (val) {
      if (this.isMobile) {
        document.querySelector('html').style.overflowY = val ? 'hidden' : 'auto'
      }
    },
    async isLogin () {
      try {
        await this.fetchAllServiceData()
      } catch (err) {
        console.log('err', err)
      }
    }
  },
  async mounted () {
    try {
      await this.fetchAllServiceData()
      if (this.isLogin && (!this.$route.path.includes('member/orderList') || !this.$route.path.includes('member/order'))) {
        await this.$store.dispatch('order/checkHasOrderIsQuotedAndUnread')
      }
    } catch (err) {
      console.log('err', err)
      this.serviceIsLoading = false
    }
  },
  methods: {
    findTypeParentId (serviceId) {
      const serviceParent = this.allService.find(menu => menu.typeParent !== -999 && menu.Main.some(service => service.serviceId === serviceId))
      return serviceParent.typeParent
    },
    handleFormatServiceData (service) {
      const filterUnquieChildrenType = (type, index) => {
        return service.Main.findIndex(c => c.typeId === type.typeId) === index
      }
      const formatTypeData = (d) => {
        return {
          typeId: d.typeId,
          typeName: d.typeName,
          iconName: d.iconName || null,
          mainTypeName: service.mainTypeName || '(名稱為空)',
          serviceList: service.Main.filter((t) => t.typeId === d.typeId).map(v => {
            return {
              ...v,
              typeParentId: service.typeParent === -999 ? this.findTypeParentId(v.serviceId) : service.typeParent
            }
          })
        }
      }
      service.subTypeList = service.Main
        .filter(filterUnquieChildrenType)
        .map(formatTypeData)
      return service
    },
    async fetchAllServiceData () {
      try {
        const serviceList = await this.$store.dispatch('service/getAllService', true)
        this.allService = _.cloneDeep(serviceList)
        this.allService = this.allService.map(this.handleFormatServiceData)
        this.serviceIsLoading = false
      } catch (err) {
        console.log('err')
        return Promise.reject(err)
      }
    },
    changeDropdownMenuStatusHandler (status) {
      this.showDropdownMenu = status
    },
    toggleMenuMobile () {
      this.menuMobileIsOpen = !this.menuMobileIsOpen
    },
    async clickLogout () {
      this.logoutDialogLoading = true
      await this.$store.dispatch('logOut')
      this.$store.commit('order/clearOrderIsQuotedAndUnread')
      this.$store.commit('multiplePackage/updateKTJNoPickUpOrders', null)
      this.logoutDialogOpen = false
      this.logoutDialogLoading = false
    },
    clickToHome () {
      this.$gaLogEvent('返回首頁', 'header_首頁')
    },
    logHeaderGaEvent (headerName) {
      this.$gaLogEvent('導覽列', `header_${headerName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
$headerPadding: 60px;
header {
  height: $headerHeight;
  background-color: #f2f2f2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: $headerHeight;
    width: 100%;
    box-shadow: 0pt 3pt 6pt 0pt rgba(0,0,0,0.16);
    pointer-events: none;
    z-index: 100;
  }
  .header {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 0;
    padding-left: pxTorem($headerPadding);
    padding-right: pxTorem($headerPadding);
    display: flex;
    z-index: 100;
    > a {
      display: flex;
      height: 100%;
    }
    .warning-alert {
      display: none;
    }
    .red-dot {
      display: none;
    }
    .logo {
      display: block;
      position: relative;
      width: auto;
      height: 100%;
      max-height: 40px;
      margin-right: 36px;
    }
    > h1 {
      display: flex;
      > a {
        display: flex;
      }
    }
  }
  .menu {
    flex: 1;
    .menu-button {
      display: none;
    }
    .nav-label {
      position: relative;
    }
    #menu-bar {
      $halfHeaderHeight: calc(#{$headerHeightMobile} / 2 - 150%);
      width: 22px;
      height: $headerHeightMobile;
      cursor: pointer;
      margin: 0 17px;
      .bar {
        height: 2px;
        width: 100%;
        background-color: $fontColor;
        display: block;
        border-radius: 5px;
        transition: 0.3s ease;
      }
      #bar1 {
        transform: translateY(calc(#{$halfHeaderHeight} - 4px));
      }
      #bar2 {
        transform: translateY(calc(#{$halfHeaderHeight}));
      }
      #bar3 {
        transform: translateY(calc(#{$halfHeaderHeight} + 4px));
      }
      &.is-open {
        #bar1 {
          transform: translateY(calc(#{$halfHeaderHeight} + 2px)) rotateZ(-45deg);
        }
        #bar2 {
          opacity: 0;
        }
        #bar3 {
          transform: translateY(calc(#{$halfHeaderHeight} - 2px)) rotateZ(45deg);
        }
      }
    }
  }
  .logo {
    $logoHeight: 40px;
    $marginBlock: calc((#{$headerHeight} - #{$logoHeight}) / 2);
    height: $logoHeight;
    width: auto;
    margin-top: $marginBlock;
    margin-bottom: $marginBlock;
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  header {
    height: $headerHeightMobile;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    .header {
      padding: 10px 5px;
      padding-top: calc(10px + var(--safe-area-inset-top));
      padding-left: calc(5px + var(--safe-area-inset-left));
      padding-right: calc(5px + var(--safe-area-inset-right));
      &__nav-menu {
        display: none;
      }
      .logo {
        height: 100%;
        margin: 0;
        padding-left: 5px;
      }
      .warning-alert {
        display: block;
        position: absolute;
        top: 50%;
        right: 45px;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
      }
      .red-dot {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 9px;
        width: 9px;
        border-radius: 4.5px;
        background-color: #e91e63;
        display: block;
      }
    }
    &:after {
      display: none;
    }
    .menu {
      position: fixed;
      top: $headerHeightMobile;
      left: -100%;
      width: 100vw;
      z-index: 100;
      &.is-open {
        left: 0;
      }
      .menu-button {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        right: calc(var(--safe-area-inset-right));
        height: $headerHeightMobile;
      }
    }
  }
}
</style>
